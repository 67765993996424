<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>用户管理</span>
				<div class="title-right">
					<ul>
						<li>主页</li>
						<li>/</li>
						<li>管理</li>
						<li>/</li>
						<li>用户管理</li>
					</ul>
				</div>
			</div>
			<div class="block-area">
				<div class="tile" style="max-height:370px">
					<h2 class="tile-title">所有用户</h2>
					<table class="table">
						<thead>
							<tr>
								<th>序号</th>
								<th>用户名</th>
								<th>姓名</th>
								<th>所属公司</th>
								<th>角色</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>Jhon</td>
								<td>张三</td>
								<td>青海移动</td>
								<td>班长</td>
							</tr>
							<tr>
								<td>2</td>
								<td>Admin</td>
								<td>李四</td>
								<td>青海移动</td>
								<td>系统管理员</td>
							</tr>
							<tr>
								<td>3</td>
								<td>super</td>
								<td>王丽丽</td>
								<td>青海移动</td>
								<td>超级用户</td>
							</tr>
							<tr>
								<td>4</td>
								<td>duoduo</td>
								<td>钱多多</td>
								<td>山东移动</td>
								<td>普通用户</td>
							</tr>
							<tr>
								<td>5</td>
								<td>super</td>
								<td>小红</td>
								<td>山东移动</td>
								<td>普通用户</td>
							</tr>
							<tr>
								<td>6</td>
								<td>super</td>
								<td>小明</td>
								<td>山东移动</td>
								<td>普通用户</td>
							</tr>
							<tr>
								<td>7</td>
								<td>super</td>
								<td>张大千</td>
								<td>青海移动</td>
								<td>普通用户</td>
							</tr>
						</tbody>
					</table>
					<div class="btn">
						<span>新增</span>
						<span>修改</span>
						<span>删除</span>
					</div>
				</div>
				<div class="tile">
					<h2 class="tile-title">用户信息</h2>
					<table class="table">
						<thead>
							<tr>
								<th>属性名</th>
								<th>属性值</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>用户名</td>
								<td>Jhon</td>
							</tr>
							<tr>
								<td>姓名</td>
								<td>张三</td>
							</tr>
							<tr>
								<td>所属公司</td>
								<td>青海移动</td>
							</tr>
							<tr>
								<td>角色</td>
								<td>值班班长</td>
							</tr>
							<tr>
								<td>邮箱</td>
								<td>zhangsan@163.com</td>
							</tr>
							<tr>
								<td>移动电话</td>
								<td>15689567895</td>
							</tr>
							<tr>
								<td>办公电话</td>
								<td>564896</td>
							</tr>
							<tr>
								<td>家庭电话</td>
								<td>7899466</td>
							</tr>
							<tr>
								<td>职务</td>
								<td>班长</td>
							</tr>
							<tr>
								<td>级别</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
export default {
	components: {
		sidebar,
		headerTop,
	},
	data() {
		return {
			date: '',
		}
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		font-size: 14px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.block-area {
			display: flex;
			.tile {
				flex: 1;
				background: rgba(0, 0, 0, 0.35);
				margin: 20px 5px;
				color: #fff;
				.tile-title {
					font-size: 13px;
					text-transform: uppercase;
					display: inline-block;
					margin: 0;
					width: 100%;
					padding: 7px 10px 7px;
					background: rgba(0, 0, 0, 0.45);
				}
				.table {
					width: 100%;
					max-width: 100%;
					thead {
						background: rgba(0, 0, 0, 0.65);
						th {
							font-weight: normal;
							padding: 5px 8px 6px;
							text-transform: uppercase;
							border-bottom: 0;
						}
					}
					tbody {
						background: rgba(0, 0, 0, 0.45);
						td {
							padding: 8px;
							line-height: 1.42857143;
							vertical-align: top;
							border-top: 1px solid rgba(255, 255, 255, 0.15);
						}
					}
				}
				.btn {
					span {
						width: 46px;
						height: 30px;
						color: #ddd;
						border: 1px solid rgba(255, 255, 255, 0.1);
						display: inline-block;
						text-align: center;
						line-height: 30px;
						border-radius: 4px;
						margin-top: 20px;
						cursor: pointer;
					}
					span:hover {
						background: rgba(255, 255, 255, 0.39) !important;
						color: #000;
					}
				}
			}
		}
	}
}
</style>
